import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import { withTranslation } from 'react-i18next' 

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    var width;

    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }

    this.state = {
      filteredSteps: [this.props.steps[0]],
      currentStep: 0,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      movingTabStyle: {
        transition: "transform 0s"
      },
      allStates: {},
      selected: ""
    };

    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.addAnotherButtonClick = this.addAnotherButtonClick.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
  }

  wizard = React.createRef();

  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }

  getChildState = (childData) => {
    let steps = [];
    steps.push(this.props.steps[0]);

    this.props.steps.forEach(e => {
      if (e.condition == childData) {
        steps.push(e)
      }
    });

    steps.push(this.props.steps[this.props.steps.length - 1]);

    this.setState({
      filteredSteps: steps
    });
  }

  navigationStepChange(key) {
    if (this.state.filteredSteps) {
      var validationState = true;
      if (key > this.state.currentStep) {
        for (var i = this.state.currentStep; i < key; i++) {
          if (this[this.state.filteredSteps[i].stepId].sendState !== undefined) {
            this.setState({
              allStates: {
                ...this.state.allStates,
                [this.state.filteredSteps[i].stepId]: this[
                  this.state.filteredSteps[i].stepId
                ].sendState()
              }
            });
          }

          if (
            this[this.state.filteredSteps[i].stepId].isValidated !== undefined &&
            this[this.state.filteredSteps[i].stepId].isValidated() === false
          ) {
            validationState = false;
            break;
          }
        }
      }
      if (validationState) {
        this.setState({
          currentStep: key,
          nextButton: this.state.filteredSteps.length > key + 1 ? true : false,
          previousButton: key > 0 ? true : false,
          finishButton: this.state.filteredSteps.length === key + 1 ? true : false
        });
        this.refreshAnimation(key);
      }
    }
  }

  nextButtonClick() {
    if (
      (this.props.validate &&
        ((this[this.state.filteredSteps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.state.filteredSteps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.state.filteredSteps[this.state.currentStep].stepId].isValidated ===
          undefined)) ||
      this.props.validate === undefined
    ) {
      if (
        this[this.state.filteredSteps[this.state.currentStep].stepId].sendState !== undefined
          && this.state.currentStep !== 0
      ) {
        let stepArray = [];
        let data = this[this.state.filteredSteps[this.state.currentStep].stepId].sendState();

        if(this.state.allStates[data.tarjeta] !== undefined){
          this.state.allStates[data.tarjeta].forEach(st => {
            stepArray.push(st);
          })
        }

        stepArray.push(this[this.state.filteredSteps[this.state.currentStep].stepId].sendState())

        this.setState({
          allStates: {
            ...this.state.allStates,
            [data.tarjeta]: stepArray
          }
        });
      }

      var key = this.state.currentStep + 1;

      this.setState({
        currentStep: key,
        nextButton: this.state.filteredSteps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.state.filteredSteps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  }

  previousButtonClick() {
    if (
      this[this.state.filteredSteps[this.state.currentStep].stepId].sendState !==
      undefined
    ) {
      // this.setState({
      //   allStates: {
      //     ...this.state.allStates,
      //     [this.state.filteredSteps[this.state.currentStep].stepId]: this[
      //       this.state.filteredSteps[this.state.currentStep].stepId
      //     ].sendState()
      //   }
      // });
    }
    var key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.state.filteredSteps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.state.filteredSteps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  }

  finishButtonClick() {
    if (
      (this.props.validate === false &&
        this.props.finishButtonClick !== undefined) ||
      (this.props.validate &&
        ((this[this.state.filteredSteps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.state.filteredSteps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.state.filteredSteps[this.state.currentStep].stepId].isValidated ===
          undefined) &&
        this.props.finishButtonClick !== undefined)
    ) {
      this.setState(
        {
          allStates: {
            ...this.state.allStates,
            [this.state.filteredSteps[this.state.currentStep].stepId]: this[
              this.state.filteredSteps[this.state.currentStep].stepId
            ].sendState()
          }
        },
        () => {
          this.props.finishButtonClick(this.state.allStates);
        }
      );
    }
  }

  addAnotherButtonClick() {
    if (
      (this.props.validate &&
        ((this[this.state.filteredSteps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.state.filteredSteps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.state.filteredSteps[this.state.currentStep].stepId].isValidated ===
          undefined)) ||
      this.props.validate === undefined
    ) {
      if (
        this[this.state.filteredSteps[this.state.currentStep].stepId].sendState !==
        undefined
      ) {
        let stepArray = [];
        let data = this[this.state.filteredSteps[this.state.currentStep].stepId].sendState();

        if(this.state.allStates[data.tarjeta] !== undefined){
          this.state.allStates[data.tarjeta].forEach(st => {
            stepArray.push(st);
          })
        }

        stepArray.push(this[this.state.filteredSteps[this.state.currentStep].stepId].sendState())

        this.setState({
          allStates: {
            ...this.state.allStates,
            [data.tarjeta]: stepArray
          }
        });
      }

      var key = 0;

      this.setState({
        currentStep: key,
        nextButton: this.state.filteredSteps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.state.filteredSteps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  }

  refreshAnimation(index) {
    var total = this.state.filteredSteps.length;
    var li_width = 100 / total;
    var total_steps = this.state.filteredSteps.length;
    var move_distance =
      this.wizard.current.children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.wizard.current.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: li_width + "%" });

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
    };
    this.setState({ movingTabStyle: movingTabStyle });
  }

  deleteCard(card) {
    const state = this.state;
    delete state.allStates[card];
    this.setState(state);
  }

  render() {
    const { classes, title, subtitle, color, t } = this.props;
    const steps = this.state.filteredSteps;

    return (
      <div className={classes.wizardContainer} ref={this.wizard}>
        <Card className={classes.card}>
          <div className={classes.wizardHeader}>
            <h3 className={classes.title}>{title}</h3>
            <h5 className={classes.subtitle}>{subtitle}</h5>
          </div>
          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <li
                    className={classes.steps}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <a
                      href="#pablo"
                      className={classes.stepsAnchor}
                      onClick={e => {
                        e.preventDefault();
                        this.navigationStepChange(key);
                      }}
                    >
                      {prop.stepName}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div
              className={classes.movingTab + " " + classes[color]}
              style={this.state.movingTabStyle}
            >
              {steps[this.state.currentStep].stepName}
            </div>
          </div>
          <div className={classes.content}>
            {steps.map((prop, key) => {
              const stepContentClasses = cx({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key
              });
              return (
                <div className={stepContentClasses} key={key}>
                  <prop.stepComponent
                    innerRef={node => (this[prop.stepId] = node)}
                    allStates={this.state.allStates}
                    childStateCallback={this.getChildState}
                    deleteCard={this.deleteCard}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.footer}>
            <div className={classes.left}>
              {this.state.previousButton ? (
                <Button
                  className={this.props.previousButtonClasses}
                  onClick={() => this.previousButtonClick()}
                >
                  {t('wizard.button.previous')}
                </Button>
              ) : null}
            </div>
            <div className={classes.right}>
              {this.state.nextButton ? (
                <>
                  {this.state.currentStep != 0 ?
                    <Button
                      color="rose"
                      className={this.finishButtonClasses}
                      onClick={() => this.addAnotherButtonClick()}
                    >
                      {t('wizard.button.addAnother')}
                    </Button>
                    : <></>}
                  <Button
                    color="rose"
                    className={this.props.nextButtonClasses}
                    onClick={() => this.nextButtonClick()}
                  >
                    {t('wizard.button.next')}
                  </Button>
                </>
              ) : null}
              {this.state.finishButton ? (
                <Button
                  color="rose"
                  className={this.finishButtonClasses}
                  onClick={() => this.finishButtonClick()}
                >
                  {t('wizard.button.finish')}
                </Button>
              ) : null}
            </div>
            <div className={classes.clearfix} />
          </div>
        </Card>
      </div>
    );
  }
}

Wizard.defaultProps = {
  color: "rose",
  title: "Here should go your title",
  subtitle: "And this would be your subtitle",
  previousButtonText: "Anterior",
  previousButtonClasses: "",
  nextButtonClasses: "",
  nextButtonText: "Siguiente",
  finishButtonClasses: "",
  finishButtonText: "Finalizar",
  addAnotherButtonText: "Agregar Otra"
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.object.isRequired,
      stepId: PropTypes.string.isRequired,
      conditional: PropTypes.string
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  addAnotherButtonClick: PropTypes.func,
  validate: PropTypes.bool
};

export default withTranslation()(withStyles(wizardStyle)(Wizard));
