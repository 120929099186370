const sourceContactList = [
    'Seleccione fuente de contacto',
    'Whatsapp',
    'Facebook',
    'Instagram',
    'Youtube',
    'Mail',
    'Recomendado directo',
    'Otro'
];

export default sourceContactList