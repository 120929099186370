import React, { useEffect } from "react";
import _ from 'lodash';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import { CSVLink } from "react-csv";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { getRequest, redirectToUnforbidden, deletePayment } from 'common/Request/Requests.js'
import getTrProps from 'common/Styles/TableProps.js'
import Datetime from "react-datetime";
import Snackbar from "components/Snackbar/Snackbar.js";
import { ContactsOutlined, Report } from "@material-ui/icons";
import { isNewExpression } from "typescript";
import ExportExcel from 'react-export-excel';

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(alertStyles);
const useFormStyles = makeStyles(formStyles);

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelColumn;

export default function PaymentTableByDate(props) {
  const classes = useStyles();
  const alertClasses = useAlertStyles();
  const formStyleClasses = useFormStyles();
  const [tableData, setTableData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [selectedReservations, setSelectedReservations] = React.useState([]);
  const [summary, setSummary] = React.useState([]);
  const [bar, setBar] = React.useState(null);
  const [editBar, setEditBar] = React.useState(null);
  const [alert, setAlert] = React.useState(null);
  const [showEdit, setShowEdit] = React.useState(false);
  const [tr, setTR] = React.useState(false);
  const [payDate, setPayDate] = React.useState(new Date());
  const [transactionNumber, setTransactionNumber] = React.useState('');
  const [dateFrom, setDateFrom] = React.useState('');
  const [dateTo, setDateTo] = React.useState('');
  const [tableDataByUser, setTableDataByUser] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState();
  const [open, setOpen] = React.useState(false);
  const loading = open && users.length === 0;

  useEffect(() => {
    populateTable();
  }, [])

  useEffect(() => {
    if (selectedUser !== undefined) {
      let filterData = tableDataByUser.filter(f => f.fullname === selectedUser.fullname);
      setTableDataByUser(filterData);
    } else {
      setTableDataByUser(tableData);
    }
  }, [selectedUser]);
  const submit = (id) => {
    submitProgressBar();
    deletePayment(id).then((response) => {
      populateTable();
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px", color: "#3e3e3e" }}
          title="Pago eliminado."
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
        </SweetAlert>
      );
      removeSubmitProgressBar();
    });
  }

  const warningWithConfirmAndCancelMessage = (id) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px", color: "#3e3e3e" }}
        title="Por favor confirme eliminar pago"
        onConfirm={() => submit(id)}
        onCancel={() => cancelDetele()}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
        confirmBtnText="Eliminar"
        cancelBtnText="Cancelar"
        showCancel
      >
        Confirme por favor.
      </SweetAlert>
    );
  };

  const cancelDetele = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px", color: "#3e3e3e" }}
        title="Cancelado"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        Cancelado.
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const progressBar = () => {
    setBar(
      <CustomLinearProgress
        variant="indeterminate"
        color="primary"
        value={30}
      />
    );
  };

  const removeProgressBar = () => {
    setBar(null);
  };

  const submitProgressBar = () => {
    setEditBar(
      <CustomLinearProgress
        variant="indeterminate"
        color="primary"
        value={30}
      />
    );
  };

  const removeSubmitProgressBar = () => {
    setEditBar(null);
  };

  const populateTable = () => {
    progressBar();
    getRequest('payments').then(payResponse => {
      let payResponseData = payResponse.data;
      if (payResponseData.code === 403) {
        redirectToUnforbidden(props);
      }
      getRequest('users').then((usersResponse) => {
        let usersResponseData = usersResponse.data
        if (usersResponseData.code === 403) {
          redirectToUnforbidden(props);
        }
        let usersCombo = usersResponseData.data.map(prop => {
          return {
            id: prop.id,
            mail: prop.mail,
            fullname: prop.name + ' ' + prop.lastname
          }
        })
        setUsers(usersCombo);

        let requestedData = payResponseData.data.map((prop, key) => {
          let user = usersResponseData.data.find(f => f.id === prop.rez.user);
          prop.user = user;
          let payDate = prop.payDate != null ? prop.payDate.split('T')[0] : '';
          let parsedDate = new Date(payDate);
          let year = parsedDate.getFullYear();
          let month = parsedDate.toLocaleString('default', { month: 'short' }).toUpperCase();
          let monthYear = month + " / " + year;
          let total = prop.rez.total;
          let feeTotal = prop.rez.feeTotal;
          let feeAgency = prop.rez.feeAgency;
          let feeUser = prop.rez.feeUser;
          return {
              id: prop.id,
              user: prop.rez.user,
              fullname: user.name + ' ' + user.lastname,
              paydate: parsedDate,
              monthYear: monthYear,
              total: parseInt(total),
              feeTotal: parseInt(feeTotal),
              feeAgency: parseInt(feeAgency),
              feeUser: parseInt(feeUser),                          
          }
        });        

        let parsedData = [];
        requestedData.forEach(d => {
          let index = parsedData.findIndex(obj => obj.fullname == d.fullname & obj.monthYear == d.monthYear)
          if (index !== -1) {
            parsedData[index].total += d.total;
            parsedData[index].feeTotal += d.feeTotal;
            parsedData[index].feeAgency += d.feeAgency;
            parsedData[index].feeUser += d.feeUser;
          } else {
            parsedData.push(d);
          }
        })
        setTableDataByUser(parsedData);
        setTableData(parsedData);
        removeProgressBar();
      })
    })
  }

  const filterTableByDate = () => {
    if (dateFrom === undefined && dateTo === undefined) {
      if (selectedUser !== undefined) {
        let filteredData = tableDataByUser.filter(f => f.fullname === selectedUser.fullname)
        setTableDataByUser(filteredData);
      } else {
        setTableDataByUser(tableDataByUser);
      }
    } else if (dateFrom === ''|| dateTo === '') {
      if (!tr) {
        setTR(true);
        setTimeout(function () {
          setTR(false);
        }, 5000);
      }
    } else {
      if (dateFrom > dateTo) {
        if (!tr) {
          setTR(true);
          setTimeout(function () {
            setTR(false);
          }, 5000);
        }
      } else {
        let filterByDate = undefined;
        if (selectedUser !== undefined) {
          let filterByUser = tableDataByUser.filter(f => f.fullname === selectedUser.fullname);
          filterByDate = filterByUser.filter(f => f.paydate >= dateFrom && f.paydate <= dateTo);
        } else {
          filterByDate = tableDataByUser.filter(f => f.paydate >= dateFrom && f.paydate <= dateTo);
        }
        setTableDataByUser(filterByDate);
      }
    }
  };

  const filterEraser = () => {
      setSelectedUser(undefined);
      setDateFrom(undefined);
      setDateTo(undefined);
      setTableDataByUser(tableData);
  };

  return (
    <GridContainer>
      {alert}
      <Card>
        {bar}
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Listado de pagos por fecha</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={3} sm={3} md={3}>
              <Autocomplete
                id="customerMail-box"
                options={users}
                getOptionLabel={(option) => option.fullname}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setSelectedUser(newValue);
                  } else {
                    setSelectedUser(undefined);
                    setTableDataByUser(tableData);
                  }
                }}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                loading={loading}
                style={{ width: 300 }}
                renderInput={(params) => (<TextField {...params}
                  label="Seleccionar agente"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />)}
              />
            </GridItem>
            <GridItem xs={2} sm={2} md={2} lg={2} style={{marginTop: "15px"}}>
              <ExcelFile element={<button>Descargar Reporte</button>} filename="Excel Report">
                <ExcelSheet data={tableDataByUser} name="Reporte">
                  <ExcelColumn label="Nombre Completo" value="fullname"></ExcelColumn>
                  <ExcelColumn label="Mes/Año" value="monthYear"></ExcelColumn>
                  <ExcelColumn label="Total" value="total"></ExcelColumn>
                  <ExcelColumn label="Comisión Total" value="feeTotal"></ExcelColumn>
                  <ExcelColumn label="Comisión Empleado" value="feeUser"></ExcelColumn>
                  <ExcelColumn label="Comisión Agencia" value="feeAgency"></ExcelColumn>
                </ExcelSheet>
              </ExcelFile>
            </GridItem>
            <GridItem xs={1} sm={1} md={1} lg={1} style={{marginBottom: "60px"}}>
              <Datetime
                dateFormat="YYYY-MM"
                timeFormat={false}
                closeOnSelect={true}
                inputProps={{
                  placeholder: "Fecha Desde"
                }}
                onChange={(event) => {
                  setDateFrom(event._d);
                }}
                className={formStyleClasses.select}
                value={dateFrom}
                id="date-from"
                />
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <></>
            </GridItem>
            <GridItem xs={1} sm={1} md={1} >
              <Datetime
                dateFormat="YYYY-MM"
                timeFormat={false}
                closeOnSelect={true}
                inputProps={{
                  placeholder: "Fecha Hasta"
                }}
                onChange={(event) => {
                  setDateTo(event._d);
                }}
                className={formStyleClasses.select}
                value={dateTo}
                id="date-to"
                />
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <></>
              </GridItem>
              <GridItem xs={2} sm={2} md={2} style={{marginLeft: "-80px"}}>
                <Button
                  color="rose"
                  onClick={() => filterTableByDate()}
                  >
                  {("Filtrar")}
                </Button>
              </GridItem>  
              <GridItem xs={2} sm={2} md={2} style={{marginLeft: "-80px"}}>
              <Button
                  color="rose"
                  onClick={() => filterEraser()}
                  >
                  {("Borrar Filtros")}
                </Button>
              </GridItem>
          </GridContainer>
          <GridItem xs={12} >
            <ReactTable
              data={tableDataByUser}
              filterable
              defaultFilterMethod={(filter, row) => { return row[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase()) }}
              columns={[
                {
                  Header: "Nombre Completo",
                  accessor: "fullname"
                },
                {
                  Header: "Mes/Año",
                  accessor: "monthYear"
                },
                {
                  Header: "Total",
                  accessor: "total"
                },
                {
                  Header: "C. Total",
                  accessor: "feeTotal"
                },
                {
                  Header: "C. Emp.",
                  accessor: "feeUser"
                },
                {
                  Header: "C. Agencia",
                  accessor: "feeAgency"
                },
                {
                  Header: "Eliminar",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              getTrProps={getTrProps}
              className="-striped -highlight"
            />
          </GridItem>
        </CardBody>
        <Snackbar
          place="tr"
          color="danger"
          icon={AddAlert}
          message={("Complete las fechas antes de continuar. También verifique que la fecha DESDE sea anterior a la de HASTA.")}
          open={tr}
          closeNotification={() => setTR(false)}
          close
        />
      </Card>
    </GridContainer>
  );
}
