import React, { useState, useEffect } from 'react';
import './YearSelection.css';


function YearSelection({ selectedYear, onYearChange }) {
  const [years, setYears] = useState([]);
  //const [selectedYear, setSelectedYear] = useState('');

  // Función para generar la lista de años desde 2019 hasta el año actual
  const generateYearsList = () => {
    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from({ length: currentYear - 2018 }, (_, index) => 2019 + index);
    setYears(yearsArray);
  //  setSelectedYear(currentYear.toString());
  };

  // Actualiza la lista de años al montar el componente
  useEffect(() => {
    generateYearsList();

    // Configura un temporizador para actualizar la lista cada año
    const timer = setInterval(generateYearsList, 1000 * 60 * 60 * 24 * 365); // 1 año en milisegundos

    return () => {
      // Limpia el temporizador cuando el componente se desmonta
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
       <select class="year-select" value={selectedYear} onChange={(e) => onYearChange(e.target.value)}>
       <option key='select_year' value=''>Seleccione un año </option>
        <option key='select_todos' value='0'>Todos</option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

export default YearSelection;